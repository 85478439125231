import { whiteColor, grayColor, guyabanoColor } from 'utils/uiConstants';

export const headerStyle = (boolean: boolean) => ({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: boolean ? '64px 0 0' : '64px 0 0',
  maxWidth: '1440px',
  boxSizing: 'border-box',
  backgroundColor: boolean ? 'transparent' : guyabanoColor,
  '@media(max-width:1200px)': {
    padding: boolean ? '0' : '64px 64px 0',
  },
  '@media(max-width:1500px)': {
    padding: '64px 64px 0',
  },
});

export const headerListStyle = (boolean: boolean) => ({
  margin: boolean ? '24px 0' : 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
});

export const headerListItemStyle = (boolean: boolean) => ({
  marginRight: '12px',
  padding: 0,
  borderRadius: '50%',
  backgroundColor: boolean ? whiteColor : 'transparent',

  '&:last-child': {
    marginRight: 0,
  },
});

export const headerListItemTextStyle = {
  margin: 0,
  width: 'max-content',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayColor,
};
