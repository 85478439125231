import React from 'react';

import { Stack, Box } from '@mui/material';

import {
  footerContainerStyle,
  footerDescriptionStyle,
  footerLinkStyle,
  footerSubtitleStyle,
  footerTitleStyle,
} from 'ui/footer/footer';
import { Logo } from 'ui/Logo';
import { getCompanyFullName } from 'utils/companyName';

export const Footer: React.FC = () => (
  <Box
    component="footer"
    sx={{
      maxWidth: '1440px',
      width: '100%',
      flexShrink: 0,

      '@media(max-width:1500px)': {
        padding: '0 64px',
      },
    }}
  >
    <Stack direction="row" sx={footerContainerStyle}>
      <Stack
        direction="row"
        sx={{
          gap: '64px',
        }}
      >
        <Logo variant="secondary" />

        <Box
          component="a"
          href="tel:+74996480003"
          style={{
            textDecoration: 'none',
          }}
        >
          <Stack>
            <Stack sx={footerSubtitleStyle}>Позвоните нам</Stack>
            <Stack sx={footerTitleStyle}>8 (499) 648-00-03</Stack>
          </Stack>
        </Box>

        <Box
          component="a"
          href="mailto:info@bestdoctor.ru"
          style={{
            textDecoration: 'none',
          }}
        >
          <Stack>
            <Stack sx={footerSubtitleStyle}>Напишите нам</Stack>
            <Stack sx={footerTitleStyle}>info@bestdoctor.ru</Stack>
          </Stack>
        </Box>
      </Stack>

      <Stack
        sx={{
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <Stack
          sx={{
            textAlign: 'end',
          }}
        >
          <Box
            component="a"
            href="https://docs.bestdoctor.ru/law/Политика%20ПДн%20Бестдоктор.pdf"
            target="_blanc"
            sx={footerLinkStyle}
          >
            Политика обработки персональных данных
          </Box>
          <Box component="p" sx={footerDescriptionStyle}>
            {`2015-${new Date().getFullYear()} ${getCompanyFullName()}`}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  </Box>
);
